<template lang="pug">
	.dateInput(:class="{ disabled }")
		label
			slot
		.container(:class="{ open }")
			.inputContainer(@click="toggleOpen" :class="{ selected }")
				input(
					:placeholder="placeholder"
					v-model="selectedMonth"
				)
			.dateSelector
				.year
					button(@click="changeYear(-1)").arrow
						FontAwesomeIcon(:icon="icons.faArrowLeft")
					button {{ selectedYear }}
					button(@click="changeYear(1)" :disabled="selectedYear === currentYear").arrow
						FontAwesomeIcon(:icon="icons.faArrowRight")
				.months
					ul
						li(v-for="month, index in months" :class="{ selectedMonth: month === selectedMonth }")
							button(
								@click="selectMonth(index)"
								:disabled="isMonthDisabled(index)"
							) {{ month }}
				.buttons
					button(@click="setDate" :disabled="!selectedMonth && !selectedYear").confirm Confirmar
					button(@click="toggleOpen").cancel Cancelar
</template>

<script>
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default {
	name: "component-date-picker",
	props: {
		placeholder: {
            type: String,
            required: false,
        },
		disabled: {
			type: Boolean,
			required: false
		},
		date: {
			type: Object,
			required: false,
			default: () => ({}),
		}
	},
	data() {
		return {
			icons: {
				faArrowRight,
				faArrowLeft
			},
			open: false,
			currentYear: null,
			currentMonth: null,
			selectedYear: null,
			selectedMonth: null,
			selected: false,
			months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
		}
	},
	watch: {
		date: {
			handler(newValue) {
				if (newValue) {
					this.selectedYear = newValue.year;
					this.selectedMonth = this.months[newValue.month];
					this.selected = true
				} else {
					this.selectedYear = null;
					this.selectedMonth = null;
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		toggleOpen() {
			this.open = !this.open
		},
		setDate() {
			let date = {
				year: this.selectedYear,
				month: this.months.findIndex(item => this.selectedMonth === item)
			}
			this.$emit('update:date', date);
			this.selected = true
			this.toggleOpen()
		},
		selectMonth(monthIndex) {
			this.selectedMonth = this.months[monthIndex]
		},
		changeYear(value) {
			const newYear = this.selectedYear + value;

			if (newYear === this.currentYear) {
				const selectedMonthIndex = this.months.indexOf(this.selectedMonth);
				if (selectedMonthIndex > this.currentMonth) {
					this.selectedMonth = null;
				}
			}

			this.selectedYear = newYear;
		},
		isMonthDisabled(index) {
			if (this.selectedYear !== this.currentYear) {
				return false;
			}
			return index > this.currentMonth;
		},
		setDaseBasedOnCurrentMonth() {
			const currentDate = new Date();
			this.currentYear = currentDate.getFullYear()
			this.currentMonth = currentDate.getMonth()

			if (this.currentMonth === 0) {
				this.currentMonth = 11;
				this.currentYear -= 1;
			} else {
				this.currentMonth -= 1;
			}
		}
	},
	mounted() {
		this.setDaseBasedOnCurrentMonth()
	}
}
</script>

<style lang="stylus" scoped src="./DatePicker.styl"></style>
