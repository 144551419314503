<template lang="pug">
	section#tabela
		.positions
			.position(:class="{ noData: !phraseRanking.domainReports[0].phrases[0].initialPosition }")
				.label
					FontAwesomeIcon(:icon="icons.faCalendarDays")
					p Posição Inicial
				.info
					p {{ phraseRanking.domainReports[0].phrases[0].initialPosition ? phraseRanking.domainReports[0].phrases[0].initialPosition : `-`}}
			.position(:class="{ noData: !phraseRanking.domainReports[0].phrases[0].initialPosition}")
				.label
					FontAwesomeIcon(:icon="icons.faFlagCheckered")
					p Posição Final
				.info
					p {{ phraseRanking.domainReports[0].phrases[0].finalPosition ? phraseRanking.domainReports[0].phrases[0].finalPosition : `- `}}
					.change(v-if="phraseRanking.domainReports[0].phrases[0].finalPosition" :class="{ trendUp: positionChange.trend === 'up', trendDown: positionChange.trend === 'down'}")
						span {{ positionChange.value }}
						FontAwesomeIcon(:icon="positionChange.trend === 'up' ? icons.faArrowTrendUp : icons.faArrowTrendDown")
		.tableContainer
			.table
				.head
					.row
						.header
						.header Dia
						.header Posição da página
				.results(v-if="phraseRanking.domainReports[0].phrases[0].initialPosition")
					.row(v-for="rank, index in phraseRanking.domainReports[0].phrases[0].histories" :key="rank.id")
						.cell
						.cell {{ formatDate(rank.createdAt) }}
						.cell {{ rank.ranking }}
							FontAwesomeIcon(v-if="index === 0" :icon="icons.faCrown")
				.emptyResults(v-else)
					.emptyBox
						FontAwesomeIcon(:icon="icons.faFaceSurprise")
						p.top Ainda não existem dados para geração de relatórios
						p.bottom Isso acontece pois o domínio ainda não chegou na terceira página.
</template>

<script>
import { faCalendarDays, faFlagCheckered, faArrowTrendUp, faArrowTrendDown, faCrown } from '@fortawesome/free-solid-svg-icons'
import { faFaceSurprise } from '@fortawesome/free-regular-svg-icons'
export default {
	name: "section-tabela",
	props: {
		phraseRanking: {
            type: Object,
            required: true,
			default: () => {}
        },
	},
	data() {
		return {
			icons: {
				faCalendarDays,
				faFlagCheckered,
				faArrowTrendUp,
				faArrowTrendDown,
				faCrown,
				faFaceSurprise
			},
			positionChange: {
				value: null,
				trend: ''
			},
		}
	},
	watch: {
		phraseRanking() {
			this.positionChange.value = this.phraseRanking.domainReports[0].phrases[0].initialPosition - this.phraseRanking.domainReports[0].phrases[0].finalPosition
			if (this.positionChange.value > 0) {
				this.positionChange.trend = 'up'
			} else {
				this.positionChange.trend = 'down'
			}
		}
	},
	methods: {
		formatDate(dateStr) {
			const corrected = dateStr.replace(':T', 'T');
			const date = new Date(corrected);
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = date.getFullYear();
			return `${day}/${month}/${year}`;
		}
	}
}
</script>

<style lang='stylus' scoped src='./Tabela.styl'></style>