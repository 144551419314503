<template lang="pug">
	.selectText(ref="selectComponent" :class="{ disabled }")
		label
			slot
		.container(:class="{ open }")
			.inputContainer(@click="toggleList")
				input(
					v-model="searchText" 
					v-bind="$attrs" 
					@input="filterItems"
					:placeholder="placeholder"
					:class="{ selected }"
				)
			ul
				li(
					v-for="item in filteredItems" 
					:class="{ disabled: isBlocked(item) }" 
					@click="selectItem(item)"
				) {{ item[itemProperties[0]] }} 
					span(v-if="itemProperties[1]") - {{ item[itemProperties[1]] }}
</template>

<script>

export default {
	name: "component-select-text-input",
	props: {
		itemList: {
			type: Array | Object,
			required: true	 
		},
		placeholder: {
			type: String,
			required: false,
		},
		itemProperties: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		},
		info: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		blockProperty: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			searchText: '',
			open: false,
			selected: null,
			filteredItems: []
		}
	},
	watch: {
		selected() {
			this.$emit('update:info', this.selected);
		},
		itemList: {
			handler(newValue) {
				this.filteredItems = [...newValue];
			},
			immediate: true,
			deep: true
		},
		info: {
			handler(newValue) {
				if (newValue) {
					this.searchText = newValue[this.itemProperties[0]] || '';
					this.selected = newValue;
				} else {
					this.searchText = '';
					this.selected = null;
				}
			},
			immediate: true,
			deep: true
		}
	},	
	methods: {
		toggleList() {
			if (!this.open) {
				this.open = true;
				return
			}
			this.open = false;
		},
		closeList() {
			this.open = false;
		},
		isBlocked(item) {
			if (!this.blockProperty) return false;
			if (Array.isArray(item) && item.length > 0) {
				return !item[0][this.blockProperty] || item[0][this.blockProperty].length === 0;
			}
			return !item[this.blockProperty] || item[this.blockProperty].length === 0;
		},
		filterItems() {
			const search = this.retira_acentos(this.searchText.toLowerCase());
			if (search === '') {
				this.filteredItems = [...this.itemList];
			} else {
				this.filteredItems = this.itemList.filter((item) =>
					this.retira_acentos(item[this.itemProperties[0]].toLowerCase()).includes(search)
				);
			}
			this.open = true;
		},
		selectItem(item) {
			if (this.isBlocked(item)) return;
			this.searchText = item[this.itemProperties[0]];
			this.selected = item
			this.open = false;
		},
		retira_acentos(str) {
			const com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
			const sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
			let novastr="";
			for(let i=0; i<str.length; i++) {
				let troca=false;
				for (let a=0; a<com_acento.length; a++) {
					if (str.substr(i,1)==com_acento.substr(a,1)) {
						novastr+=sem_acento.substr(a,1);
						troca=true;
						break;
					}
				}
				if (troca==false) {
					novastr+=str.substr(i,1);
				}
			}
			return novastr;
		},
		handleClickOutside(event) {
			const component = this.$refs.selectComponent;
			if (component && !component.contains(event.target)) {
				this.closeList();
			}
		},
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	},
}
</script>

<style lang="stylus" scoped src="./SelectTextInput.styl"></style>
