<template lang="pug">
	Container
		main#reports(v-if="customers.length > 0")
			.selects
				SelectTextInput(
					:itemList="customers" 
					placeholder="Nome sobrenome"
					v-bind:info.sync="selectedCustomer"
					:itemProperties="['name', 'email']"
				)
					span Cliente
				SelectTextInput(
					:itemList="domains" 
					placeholder="Domínio"
					v-bind:info.sync="selectedDomain"
					:itemProperties="['name']"
					:disabled="!this.selectedCustomer"
					blockProperty="phrases"
				)
					span URL
				SelectTextInput(
					:itemList="phrases" 
					placeholder="Frase"
					v-bind:info.sync="selectedPhrase"
					:itemProperties="['phrases']"
					:disabled="!this.selectedCustomer"
				)
					span Frase
				DatePicker(
					placeholder="Selecione o Período"
					v-bind:date.sync="selectedDate"
					:disabled="!this.selectedCustomer"
				)
					span Período
			Tabela(:phraseRanking="ranking")
</template>

<script>
import Container from '@components/Container/Container'
import SelectTextInput from '@components/SelectTextInput/SelectTextInput'
import DatePicker from '@components/DatePicker/DatePicker'
import Tabela from '@sections/Reports/Tabela/Tabela.vue'

export default {
	name: "view-reports",
	components: {
		Container,
		SelectTextInput,
		DatePicker,
		Tabela
	},
	metaInfo() {
		return {
			title: 'Relatórios | Visibox',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	data() {
		return {
			selectedCustomer: null,
			selectedDate: null,
			selectedDomain: null,
			selectedPhrase: null,
			customers: [],
			domains: [],
			phrases: [],
			ranking: {}
		}
	},
	watch: {
        selectedCustomer() {
            this.domains = this.selectedCustomer.domains || []
			this.selectedDomain = this.domains[0] || null

			const currentDate = new Date();
			let currentYear = currentDate.getFullYear();
			let currentMonth = currentDate.getMonth();
			if (currentMonth === 0) {
				currentMonth = 11;
				currentYear -= 1;
			} else {
				currentMonth -= 1;
			}
			this.selectedDate = { year: currentYear, month: currentMonth };
        },
        selectedDomain() {
			this.phrases = this.selectedDomain?.phrases || []
			this.selectedPhrase = this.phrases[0] || null
        },
		selectedDate() {
			this.loadPhraseRankings()
		},
		selectedPhrase() {
			if (this.selectedDate.year) {
				this.loadPhraseRankings()
			}
		}
    },
	methods: {
		loadCustomers() {
			this.$axios.get('customers/')
				.then(response => {
					this.customers = response.data
				})
		},
		loadPhraseRankings() {
			this.$axios.get(`/reports/customer/${this.selectedCustomer.id}/domain/${this.selectedDomain.id}/phrase/${this.selectedPhrase.id}?year=${this.selectedDate.year}&month=${this.selectedDate.month}`)
				.then(response => {
					this.ranking = response.data
				})
		}
	},
	mounted() {
		this.loadCustomers()
	}
}
</script>

<style lang="stylus" scoped src="./Reports.styl"></style>
